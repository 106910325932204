import * as React from "react";
import { useTranslation } from "@elevio/kb-kit/lib/hooks";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import WithTitle from '@elevio/kb-kit/lib/componentsInternal/WithTitle';
function Page() {
    const { t } = useTranslation();
    const [lang, setLang] = React.useState('en');
    React.useEffect(() => {
        const interval = window.setInterval(function () {
            if (document.cookie) {
                let selectedLang = check_cookie_name('googtrans');
                if (lang !== selectedLang) {
                    setLang(selectedLang);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    function check_cookie_name(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
        else {
            return '/en/en';
        }
    }
    return (<PageLayout className="hide-submit-request" header={<Header />} footer={<Footer />}>
      <WithTitle title="Submit Request" children></WithTitle>
     <div id="request-page" className="request-page">
          <iframe src={`${process.env.PRIVATE_SUBMISSION_URL}?translate=${lang}`}/>
    </div>
    </PageLayout>);
}
export default Page;
